import ContentSection from "../../components/contentSection";
import ImageWithFallback from "../../components/imageWithFallback";

const Services = () => {
    return (
      <ContentSection backgroundImageClass="homeSection" >
        <div className="container">
          <div className="row">
              <div className="col-lg-5">
                  <div className="mt-40 home-2-content">
                      <h1 className="text-white fw-normal home-2-title display-4">Our services.</h1>
                      <p>
                        Our expert team with almost two decades of experience will guarantee the success of your next project. Cross-platform, develop once, deploy everywhere that will guarantee cost reduction and speed to market.
                      </p>
                      <p>
                        Api design, DevOps, Consulting and Sitecore to name a few areas where we take proud to be part of.
                      </p>
                  </div>
              </div>
              <div className="col-lg-7 align-items-center">
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-6 col-6 image-space">
                    <ImageWithFallback src="/images/api.webp" fallback="/images/api.png" alt="API experts" className="mx-auto d-block"  width={100}/>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 col-6 image-space">
                    <ImageWithFallback src="/images/sitecore.webp" fallback="/images/sitecore.png" alt="Sitecore experts" className="mx-auto d-block"  width={100}/>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-6 col-6 image-space">
                    <ImageWithFallback src="/images/azure-cloud.webp" fallback="/images/azure-cloud.png" alt="Azure experts" className="mx-auto d-block"  width={100}/>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 col-6 image-space">
                    <ImageWithFallback src="/images/devops.webp" fallback="/images/devops.png" alt="Dev Ops experts" className="mx-auto d-block"  width={100}/>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </ContentSection>
    );
  };
  
  export default Services;