import { FunctionComponent } from "react";

interface ImageWithFallbackProps extends React.ComponentPropsWithoutRef<"img"> {
  src: string,
  fallback: string,
  type?: string
}

const ImageWithFallback: FunctionComponent<ImageWithFallbackProps> = ({src, fallback, type, ...props}) => {
    return (
      <picture>
        <source srcSet={src} type={type ?? 'image/webp'} />
        <img src={fallback} alt={props.alt} {...props} />
      </picture>
    );
  };

export default ImageWithFallback;