import ContentSection from "../../components/contentSection";
import { Link } from "react-router-dom";
import ImageWithFallback from "../../components/imageWithFallback";


const Home = () => {


  return (
    <ContentSection backgroundImageClass="homeSection" >
      <div className="container">
        <div className="row">
            <div className="col-lg-5">
                <div className="mt-40 home-2-content">
                    <h1 className="text-white fw-normal home-2-title display-4">Delivering success.</h1>
                    <p>
                      We are a team of passionate technology experts dedicated to providing cutting-edge solutions to businesses of all sizes.
                    </p>
                    <p>
                      At our core, we believe that technology should be easy to use, intuitive, and reliable. That's why we work closely with our clients to understand their unique needs and deliver customized solutions that meet their specific requirements.
                    </p>
                    <div className="mt-5">
                      <Link to="/services" className="button me-4">
                        Learn More
                      </Link>
                    </div>
                </div>
            </div>
            <div className="col-lg-7">
                <div className="mt-40 home-2-content position-relative bounceImage">
                  <ImageWithFallback src="/images/home_hero.webp" fallback="/images/home_hero.png" alt="Team" className="img-fluid mx-auto d-block home-2-img mover-img" />
                </div>
            </div>
        </div>
      </div>
    </ContentSection>
    
  );
};

export default Home;