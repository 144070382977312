import * as React from "react";
import Section from "../models/section";

const ContentSection = (props: React.PropsWithChildren<Section>) => {
    return (
        <div className={props.backgroundImageClass + " contentSection"}>
            {props.children}
        </div>
    )
}

export default ContentSection;