import ContentSection from "../../components/contentSection";

const Contact = () => {
    return (
      <ContentSection backgroundImageClass="homeSection" >
        <div className="container">
          <div className="align-items-center row">
              <div className="col-lg-5">
                  <div className="mt-40 home-2-content">
                      <h1 className="text-white fw-normal home-2-title display-4">Contact us.</h1>
                      <p>
                        Welcome to our Dev Hub Pro! We are excited to provide you with top-notch technology solutions that will take your business to the next level. If you have any questions or would like to schedule a consultation, please don't hesitate to reach out to us.
                      </p>
                      <p>
                        Phone: <a href="tel:0737093759" className="actionLink hover-underline-animation-blue"> (07) 3709 3759</a>
                      <br />
                        Email: <a href="mailto:contact@devhub.pro?subject=Enquire" className="actionLink hover-underline-animation-blue">contact@devhub.pro</a>
                      </p>
                      <p>
                        We look forward to hearing from you and helping you achieve your project goals!
                      </p>
                  </div>
              </div>
          </div>
        </div>
      </ContentSection>
    );
  };
  
  export default Contact;