import ContentSection from "../../components/contentSection";
import ImageWithFallback from "../../components/imageWithFallback";

const About = () => {
    return (
      <ContentSection backgroundImageClass="homeSection" >
        <div className="container">
          <div className="align-items-center row">
              <div className="col-lg-5">
                  <div className="mt-40 home-2-content">
                      <h1 className="text-white fw-normal home-2-title display-4">About us.</h1>
                      <p>
                        Welcome to Dev Hub Pro! We are a team of passionate professionals dedicated to providing innovative technology solutions to businesses of all sizes.
                      </p>
                      <p>
                        Our company was founded with the vision of empowering businesses to leverage technology to achieve their goals. We believe that technology should be an enabler of growth and success, and we strive to make that a reality for our clients.
                      </p>
                      <p>
                        At our core, we are a team of problem-solvers. Specialized in Azure .NET and Sitecore, we love nothing more than tackling complex challenges and finding creative solutions to help our clients overcome their biggest obstacles.
                      </p>
                      <p>
                        We believe that the success of our company is directly tied to the success of our clients. That's why we approach every engagement with a collaborative mindset and a commitment to delivering results that exceed expectations. We take pride in our ability to build long-term relationships with our clients and to help them achieve their business objectives year after year.
                      </p>
                  </div>
              </div>
              <div className="col-lg-7">
                <div className="mt-40 position-relative" style={{opacity: .2}}>
                    <ImageWithFallback src="/images/about_hero.webp" fallback="/images/about_hero.jpg" alt="About us" className="img-fluid mx-auto d-block home-2-img mover-img" />
                </div> 
            </div>
          </div>
        </div>
      </ContentSection>
    );
  };
  
  export default About;