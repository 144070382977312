import './app.scss';
import { Home } from './views/home';
import { Contact } from './views/contact';
import { About } from './views/about';
import Header from './components/header';
import Footer from './components/footer';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Services } from './views/services';
import { useState, useEffect } from "react";

const DescribedRoutes = () => {

  const location = useLocation();
  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransistionStage] = useState("fadeIn");
  
  useEffect(() => {
    if (location !== displayLocation) setTransistionStage("fadeOut");
  }, [location, displayLocation]);

  return (
    <div>
      <Header />
      <div className={`${transitionStage} websiteBody`}
           onAnimationEnd={() => {
             if (transitionStage === "fadeOut") {
               setTransistionStage("fadeIn");
               setDisplayLocation(location);
             }
           }}>
        <Routes location={displayLocation}>
          <Route path="/" element={ <Home/> } />
          <Route path="/services" element={<Services />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

export default DescribedRoutes;