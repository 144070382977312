import { Link } from "react-router-dom";
import {useState} from 'react';

const Header = () =>{
     const MenuItems = [
     {name: "HOME", path : "/"},
     {name: "SERVICES", path : "/services"},
    //  {name: "CLIENTS", path : "/clients"},
     {name: "ABOUT", path : "/about"},
     {name: "CONTACT US", path : "/contact"}
    ]

    const [containerClassName, setContainerClassName] = useState('');
    const [contentClassName, setContentClassName] = useState('');

    const toggleMenuOpen = () => {
        
        let isActive = containerClassName === 'menuContainerOpen';
        setContainerClassName(isActive ? 'menuContainerClose' : 'menuContainerOpen');
        setContentClassName(isActive ? 'd-none' : 'menuContentOpen');
     }

    return (
        <nav className="navbar navbar-expand-lg fixed-top navbar-custom navbar-light sticky sticky-dark " id="navbar">
            <div className="container">
                <Link to="/" className="navbar-brand logo">
                    <img src="images/DevHubLogo.svg" alt="Dev Hub Logo" className="logo" />
                </Link>
                    
                <button aria-label="Toggle navigation" type="button" data-bs-toggle="collapse" onClick={toggleMenuOpen} data-bs-target="#navbarCollapse" className="navbar-toggler navbar-toggler">
                    <i className="mdi mdi-menu"></i>
                </button>
                <div className={containerClassName + " d-none navbar-collapse mobileNavBar"}>
                    <ul className={contentClassName + " navbar-nav ms-auto navbar-center"}>
                        {
                            MenuItems.map(m => (
                                <li className="nav-item active" key={m.name}>
                                    { <Link  onClick={toggleMenuOpen} to={m.path} className="nav-link">{m.name}</Link> }
                                </li>
                            
                            ))
                        }
                        
                    </ul>
                </div>
                <div className={" desktopNavBar"}>
                    <ul className={" navbar-nav ms-auto navbar-center"}>
                        {
                            MenuItems.map(m => (
                                <li className="nav-item active" key={m.name}>
                                    { <Link  to={m.path} className="nav-link hover-underline-animation-black">{m.name}</Link> }
                                </li>
                            
                            ))
                        }
                        
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Header;