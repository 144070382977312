import * as React from "react";
import { createRoot } from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css';
import DescribedRoutes from './routes'; 
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom'
import ReactGA from "react-ga4";

const rootElement = document.getElementById("root")

const root = createRoot(rootElement);

root.render(
<BrowserRouter>
  <DescribedRoutes />
</BrowserRouter>);

//Initialize GA4
ReactGA.initialize("G-RSKJEWJJQZ");

const SendAnalytics = ()=> {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
}

reportWebVitals(SendAnalytics);